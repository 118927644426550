@import-normalize;

// Hyper Icons
@import './icons.scss';

* {
  box-sizing: border-box;
}

body {
  padding-bottom: 0;
  background-color: transparent;
}

input,
textarea,
body {
  box-sizing: border-box;
  margin: 0;
  overflow-anchor: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  outline: none;

  &:focus {
    outline: none;
  }
}

li {
  padding: 0;
}

.mainContentsAtOpenMobileSearchBox {
  display: none;
}

div.rightContainer {
  padding: 0 24px;
  min-height: 100vh;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* EXTEND */
a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

textarea:focus,
input:focus {
    outline: none;
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    outline: none;
}

button:focus {
    outline: none;
}

body {
    line-height: 1.5;
}
